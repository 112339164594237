import { useMutation } from 'react-query';
import FetchAPI from 'services/FetchAPI';
import { STRIPE_CLIENT_SECRET_URL, STRIPE_TRANSACTION_PATCH_URL, STRIPE_UPDATE_CUSTOMER_URL } from 'constants/urls';
import { useURLParams } from './useURLParams';
import { SetupIntentBillingPayload, StripeClientSecretRequest, StripeCustomerPayload } from 'models/Billing';

export const useUBSStripe = () => {
  const transactionId = useURLParams('transaction_id');
  const { mutate: getClientSecret } = useMutation((payload: StripeClientSecretRequest) => {
    const response = FetchAPI.post(`${STRIPE_CLIENT_SECRET_URL}?transaction_id=${transactionId}`, payload, 120000);
    return response;
  });

  const { mutate: stripeTransactionPatch } = useMutation((payload: SetupIntentBillingPayload) => {
    const response = FetchAPI.post(`${STRIPE_TRANSACTION_PATCH_URL}?transaction_id=${transactionId}`, payload, 120000);
    return response;
  });

  const { mutate: stripeUpdateCustomer } = useMutation((payload: StripeCustomerPayload) => {
    const response = FetchAPI.post(`${STRIPE_UPDATE_CUSTOMER_URL}?transaction_id=${transactionId}`, payload, 120000);
    return response;
  });

  return {
    getClientSecret,
    stripeTransactionPatch,
    stripeUpdateCustomer,
  };
};
