import { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { StripeClientSecretRequest } from 'models/Billing';
import { useUBSStripe } from 'hooks/useStripe';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useURLParams } from 'hooks/useURLParams';
import { useTransactionDetails } from 'hooks/useTransactionDetails';

export const InitialContextValues = {
  clientSecret: '',
  stripePromise: null,
  stripeCustomerId: '',
};

export interface StripeContextProps {
  clientSecret: string;
  stripePromise: PromiseLike<Stripe | null> | null;
  stripeCustomerId: string;
}

export const StripeContext = createContext<StripeContextProps>(InitialContextValues);

const StripeProvider = ({ children }: { children: ReactElement }) => {
  const { getClientSecret } = useUBSStripe();
  const [clientSecret, setclientSecretHook] = useState('');
  const clientSecretParam = useURLParams('setup_intent_client_secret');
  const { transactionDetails } = useTransactionDetails();
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [stripePromise, setStripePromise] = useState<PromiseLike<Stripe | null> | null>(null);
  const stripePayload: StripeClientSecretRequest = {
    stripeId: transactionDetails.stripe_customer_id,
  };

  useEffect(() => {
    if (!clientSecretParam) {
      getClientSecret(stripePayload, {
        onSuccess: (response) => {
          setclientSecretHook(response?.clientSecret);
          setStripeCustomerId(response?.stripeId);
        },
      });
    } else {
      setclientSecretHook(clientSecretParam);
    }
  }, []);
  useEffect(() => {
    if (typeof process.env.REACT_APP_STRIPE_PK === 'string') {
      const promise = loadStripe(String(process.env.REACT_APP_STRIPE_PK));
      setStripePromise(promise);
    }
  }, []);

  return (
    <StripeContext.Provider
      value={{
        clientSecret,
        stripePromise,
        stripeCustomerId,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

const useStripeContext = () => {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error('useStripeContext must be used within a StripeProvider');
  }
  return context;
};

export { StripeProvider, useStripeContext };
