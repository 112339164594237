export const TRANSACTION_URL = `/signup-api/v1/transaction`;
export const BILLING_URL = `${TRANSACTION_URL}/billing`;
export const BILLING_INFO_URL = `${TRANSACTION_URL}/billing-info`;
export const CART_URL = `${TRANSACTION_URL}/cart-summary`;
export const TRANSACTION_DETAILS_URL = `${TRANSACTION_URL}/details`;
export const COUNTRIES_URL = `${TRANSACTION_URL}/countries`;
export const REGIONS_URL = `${TRANSACTION_URL}/regions`;
export const SALES_ORDER_TRANSACTION_DETAILS_URL = `${TRANSACTION_URL}/sales-order`;
export const CYBERSOURCE_URL = `/api/cybersource`;
export const CYBERSOURCE_PAYMENTS_URL = `${CYBERSOURCE_URL}/payments`;
export const CYBERSOURCE_KEY_URL = `${CYBERSOURCE_URL}/public-key`;
export const STRIPE_CLIENT_SECRET_URL = `api/stripe/client-secret`;
export const STRIPE_TRANSACTION_PATCH_URL = '/api/stripe/transaction';
export const STRIPE_UPDATE_CUSTOMER_URL = '/api/stripe/customer';
