import FetchAPI from 'services/FetchAPI';
import { useURLParams } from 'hooks/useURLParams';
import { BillingPayload, SetupIntentBillingPayload, StripeUBSBillingPayload, UBSBillingPayload } from 'models/Billing';
import { BILLING_URL } from 'constants/urls';
import { useMutation } from 'react-query';
import { TokenResult } from '@stripe/stripe-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormikBillingValues } from 'models/Billing';

// This hook is used to submit payment information to the signups api for both new signups and update payments.
export const useBilling = () => {
  const transactionId = useURLParams('transaction_id');
  const elements = useElements();
  const stripe = useStripe();

  const { mutate: sendBillingInfo } = useMutation(
    (payload: BillingPayload | UBSBillingPayload | StripeUBSBillingPayload) =>
      FetchAPI.patch(`${BILLING_URL}/${transactionId}/`, payload),
  );

  const getStripeToken = async (values: FormikBillingValues): Promise<TokenResult | undefined> => {
    const cardElement = elements?.getElement(CardElement);

    if (stripe && cardElement && values) {
      return await stripe?.createToken(cardElement, {
        address_city: values.city,
        address_country: values.country,
        address_line1: values.billingAddress,
        address_line2: values.billingAddress2,
        address_state: values.region,
        address_zip: values.zip,
        name: values.cardholderName,
      });
    }
  };

  return { sendBillingInfo, getStripeToken };
};
